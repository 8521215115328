<template>
  <div class="container" id="timeLine">
    <el-timeline id="timeLineDate">
      <el-timeline-item
        v-for="(activity, index) in activities"
        :key="index"
        icon="el-icon-timer"
        type="primary"
        size="large"
        :timestamp="activity.timestamp"
        placement="top"
      >
        <div style="display: flex">
          <div style="display: flex" class="timeLineClass">
            <div
              style="display: flex; padding: 0"
              :style="{
                paddingTop: maxLength == 0 ? '20px' : maxLength * 20 + 'px',
                paddingBottom: maxLength == 0 ? '20px' : maxLength * 20 + 'px',
              }"
            >
              <div
                class="step"
                v-for="(item, index) in activity.timeLineList"
                :key="index"
              >
                <el-tooltip
                  v-if="spectionIndex != null && index == spectionIndex"
                  placement="bottom"
                  effect="light"
                >
                  <template #content>
                    {{ $t("i18n.lastAnnualInspectionDate") }}：
                    {{ inspection.annual_inspection_date }}<br />
                    {{ $t("i18n.aiStatus") }}：
                    <span v-if="inspection.year_check_status == 0">正常</span>
                    <span v-else-if="inspection.year_check_status == 10">
                      待年检
                    </span>
                    <span v-else-if="inspection.year_check_status == 15">
                      自检超期
                    </span>
                    <span v-else-if="inspection.year_check_status == 20">
                      逾期
                    </span>
                    <span v-else-if="inspection.year_check_status == 25">
                      超期已完成
                    </span>
                  </template>
                  <div id="circleDot" @click="enterInspection">
                    <span>检</span>
                    <div class="circle"></div>
                    <div class="circle_bottom animation"></div>
                    <div class="circle_bottom2 animation2"></div>
                  </div>
                </el-tooltip>
                <div style="flex: 1; display: flex; flex-direction: column">
                  <div style="display: flex" class="lineGroups">
                    <i
                      @click="changeYear('prev')"
                      v-if="index == 0"
                      class="icon el-icon-d-arrow-left"
                    ></i>
                    <div
                      class="item"
                      :style="{
                        borderColor: index <= month ? '#6bb9ba' : '#dcdfe6',
                      }"
                    ></div>
                    <div
                      class="dot"
                      :style="{
                        backgroundColor: getColorByStatus(
                          activity.timeLineList[index].status
                        ),
                      }"
                    ></div>
                    <div
                      class="item"
                      :style="{
                        borderColor: index <= month ? '#6bb9ba' : '#dcdfe6',
                      }"
                    ></div>
                    <i
                      v-if="index == activity.timeLineList.length - 1"
                      class="icon el-icon-d-arrow-right"
                      :class="{ disabled: year == new Date().getFullYear() }"
                      @click="changeYear('next')"
                    ></i>
                  </div>
                  <div class="item_top">
                    <span v-if="activity.timeLineList[index].timestamp">
                      {{ activity.timeLineList[index].timestamp }}
                    </span>
                  </div>
                  <el-popover placement="bottom" :width="650" trigger="hover">
                    <template #reference>
                      <a class="item_bottom">
                        <div
                          class="itemBot_list"
                          :style="{
                            backgroundColor: getColorByStatus(
                              activity.timeLineList[index].status
                            ),
                          }"
                        >
                          {{ activity.timeLineList[index].info }}
                        </div>
                      </a>
                    </template>
                    <el-table
                      height="300px"
                      :data="activity.timeLineList[index].timeLineBranch"
                      border
                    >
                      <el-table-column type="index"></el-table-column>
                      <el-table-column
                        :label="$t('i18n.orderNumber')"
                        width="180"
                      >
                        <template #default="scope">
                          <a @click="enterPage(scope.row)">
                            {{ scope.row.bill_number }}
                          </a>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="info"
                        :label="$t('i18n.orderCategory')"
                      >
                      </el-table-column>
                      <el-table-column
                        width="100"
                        :label="$t('i18n.startTime')"
                      >
                        <template #default="scope">
                          {{ scope.row.created_date.split(" ")[0] }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        width="100"
                        :label="$t('i18n.completionDate')"
                      >
                        <template #default="scope">
                          <span v-if="!scope.row.complete_time"></span>
                          <span v-else>
                            {{ scope.row.complete_time.split(" ")[0] }}
                          </span>
                        </template>
                      </el-table-column>
                      <el-table-column :label="$t('i18n.currentStatus')">
                        <template #default="scope">
                          <span
                            :style="{
                              color: getDataByStatus(scope.row.billstatus)
                                .color,
                            }"
                          >
                            {{
                              $t(
                                "i18n." +
                                  getDataByStatus(scope.row.billstatus).locale
                              )
                            }}
                          </span>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-popover>
                  <!-- 横时间轴分支 -->
                  <div
                    class="timeLineBranch"
                    v-if="
                      activity.timeLineList[index].timeLineBranch &&
                      activity.timeLineList[index].timeLineBranch.length > 0
                    "
                    :style="{
                      top:
                        index % 2 === 1
                          ? activity.timeLineList[index].timeLineBranch.length <
                            4
                            ? activity.timeLineList[index].timeLineBranch
                                .length *
                                -24 +
                              'px'
                            : '-109px'
                          : '40px',
                    }"
                  >
                    <el-scrollbar height="105px">
                      <el-timeline>
                        <el-timeline-item
                          v-for="(itemBranch, indexBranch) in activity
                            .timeLineList[index].timeLineBranch"
                          :key="indexBranch"
                          :timestamp="itemBranch.timestamp"
                          :color="getColorByType(itemBranch.bill_model)"
                        >
                          <div
                            :style="{
                              color: getColorByType(itemBranch.bill_model),
                            }"
                          >
                            {{ itemBranch.info.substring(0, 1) }}
                          </div>
                        </el-timeline-item>
                      </el-timeline>
                    </el-scrollbar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
import { getCurrentInstance, reactive, toRefs } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
export default {
  name: "timeLine",
  props: ["id"],
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();
    const state = reactive({
      billModelData: [],
      month: parseInt(new Date().getMonth() + 5),
      activities: [
        {
          timestamp: "",
          timeLineList: [],
        },
      ],
      maxLength: 0,
      year: new Date().getFullYear(),
      inspection: {},
      spectionIndex: 0,
    });

    const init = () => {
      if (parseInt(new Date().getDate()) > 15) {
        // 大于15日+1
        state.month++;
      }
      state.activities[0].timestamp = state.year;
      for (let i = 1; i < 25; i++) {
        state.activities[0].timeLineList.push({
          status: "",
          timestamp:
            i % 2 === 0
              ? ""
              : t("i18n." + proxy.$config.month[Math.floor(i / 2)]),
          info: 0,
          timeLineBranch: [],
        });
      }
      initData();
    };

    const initData = async () => {
      let { data } = await proxy.$api.system.getElevatorInfo(props.id, {
        year: state.year,
      });
      if (
        data.annual &&
        data.annual[0].annual_inspection_date &&
        (data.annual[0].year_check_status == 0 ||
          data.annual[0].year_check_status == 25)
      ) {
        state.inspection = data.annual[0];
        let insDate = data.annual[0].annual_inspection_date.split("-");
        state.spectionIndex =
          insDate[2] > 15
            ? 2 * parseInt(insDate[1])
            : 2 * parseInt(insDate[1]) - 1;
      } else {
        state.spectionIndex = null;
      }
      let list = state.activities[0].timeLineList;
      data.bill.map((item) => {
        let date = item.created_date.split(" ")[0].split("-");
        item.info = t("i18n." + getDataByType(item.bill_model));
        item.timestamp = date[2];
        item.status = item.complete_time ? "finish" : "";
        let step =
          date[2] > 15
            ? list[2 * parseInt(date[1]) - 1]
            : list[2 * parseInt(date[1]) - 2];
        step.timeLineBranch.push(item);
      });
      list.map((item) => {
        item.info = item.timeLineBranch.length;
        if (item.info > state.maxLength) {
          state.maxLength = item.info;
        }
        state.maxLength = state.maxLength > 4 ? 4 : state.maxLength;
        let current = item.timeLineBranch.filter(
          (obj) => obj.bill_model == 0 && obj.complete_time
        );
        item.status = current.length > 0 ? "finish" : "";
      });
    };

    const changeYear = (flag) => {
      // 切换年份
      if (state.year == new Date().getFullYear() && flag == "next") {
        return;
      }
      let year = parseInt(state.year);
      state.year = flag === "prev" ? year - 1 : year + 1;
      state.activities = [
        {
          timestamp: "",
          timeLineList: [],
        },
      ];
      init();
    };

    const getBillModelData = () => {
      proxy.$api.common.getLineageByCode("billModel").then((res) => {
        state.billModelData = res.data.lineages;
        init();
      });
    };
    getBillModelData();

    const getDataByType = (type) => {
      let current = state.billModelData.filter((item) => {
        return item.value == type;
      });
      return current.length > 0 ? current[0].code : "other";
    };

    const getDataByStatus = (type) => {
      let current = proxy.$config.billStatus.filter((item) => {
        return item.id == type;
      });
      return current.length > 0 ? current[0] : {};
    };

    const getColorByType = (model) => {
      let current = state.billModelData.filter((item) => {
        return item.value == model;
      });
      return current.length > 0 ? current[0].description : "#333";
    };

    const getColorByStatus = (status) => {
      if (status === "finish") {
        return "#65bdaf";
      } else if (status === "being") {
        return "#eabd60";
      } else if (status === "wating") {
        return "#e2e9f9";
      } else if (status === "timeOut") {
        return "#65bdaf";
      } else if (status === "ce3820") {
        return "#65bdaf";
      } else {
        return "";
      }
    };

    const enterPage = (data) => {
      let routeData = router.resolve({
        path: "/maintenance/workBillBoard",
        query: { billNumber: data.bill_number },
      });
      window.open(routeData.href, "_blank");
    };

    const enterInspection = () => {
      let routeData = router.resolve({
        path: "/maintenance/annualInfocou",
        query: { id: route.query.id },
      });
      window.open(routeData.href, "_blank");
    };

    return {
      ...toRefs(state),
      getColorByType,
      getDataByStatus,
      enterPage,
      getColorByStatus,
      enterInspection,
      changeYear,
    };
  },
};
</script>

<style lang="scss">
#timeLine {
  min-height: 0;
  padding: 10px 30px 0;
  .dot {
    border: 2px solid #dcdfe6;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background: white;
    margin: 2px 0px;
    box-sizing: border-box;
  }
  .item {
    flex: 1;
    border-bottom: 1px solid #dcdfe6;
    margin-bottom: 9px;
    box-sizing: border-box;
    min-width: 21px;
  }
  .item_top {
    text-align: center;
    height: 15px;
    margin-top: -40px;
    font-size: 14px;
  }
  .item_bottom {
    flex: 1;
    text-align: center;
    height: 15px;
    margin-top: 24px;
    font-size: 12px;
    .itemBot_list {
      position: relative;
      z-index: 9;
      width: 20px;
      border-radius: 5px;
      background-color: #6bb9ba;
      color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      vertical-align: middle;
    }
    .itemBot_end {
      height: 26px;
      visibility: hidden;
    }
  }
}
.step {
  position: relative;
  .lineGroups {
    position: relative;
    .icon {
      position: absolute;
      top: -2px;
      width: 24px;
      height: 24px;
      line-height: 22px;
      text-align: center;
      border-radius: 50%;
      color: #6bb6ba;
      cursor: pointer;
      border: 1px solid #6bb9ba;
    }
    .icon.disabled {
      color: #ccc;
      cursor: not-allowed;
      border-color: #ccc;
    }
    .el-icon-d-arrow-left {
      left: -24px;
    }
    .el-icon-d-arrow-right {
      right: -24px;
    }
  }
  #circleDot {
    position: absolute;
    z-index: 9;
    top: -16px;
    > span {
      margin: 0 0 3px -3px;
      display: block;
    }
    > div {
      background: red;
      border-radius: 50%;
    }
    .circle {
      width: 8px;
      height: 8px;
    }
    .circle_bottom {
      width: 10px;
      height: 10px;
      margin-top: -9px;
    }
    .circle_bottom2 {
      width: 12px;
      height: 12px;
      margin: -11px 0 0 -2px;
    }
  }
  .timeLineBranch {
    position: absolute;
    top: 40px;
    left: -17px;
    .el-timeline {
      width: 75px;
      padding-left: 40px;
    }
    .el-timeline-item__timestamp {
      position: absolute;
      left: -20px;
      top: -5px;
    }
    .el-timeline-item__wrapper {
      padding-left: 18px;
    }
    .el-timeline-item {
      padding-bottom: 10px;
    }
    .el-timeline-item__node--normal {
      left: 0;
    }
    .el-timeline .el-timeline-item .el-timeline-item__tail {
      display: block;
    }
    .el-timeline .el-timeline-item:last-child .el-timeline-item__tail {
      display: none;
    }
  }
  &:nth-child(2n) {
    .timeLineBranch {
      top: -50px;
    }
  }
}
.animation {
  -webkit-animation: twinkling 2.1s infinite ease-in-out;
  animation: twinkling 2.1s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animation2 {
  -webkit-animation: twinkling 2.1s infinite ease-in-out;
  animation: twinkling 2.1s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes twinkling {
  0% {
    opacity: 0.2;
    filter: alpha(opacity=20);
    -webkit-transform: scale(1);
  }
  50% {
    opacity: 0.5;
    filter: alpha(opacity=50);
    -webkit-transform: scale(1.12);
  }
  100% {
    opacity: 0.2;
    filter: alpha(opacity=20);
    -webkit-transform: scale(1);
  }
}
@keyframes twinkling {
  0% {
    opacity: 0.2;
    filter: alpha(opacity=20);
    -webkit-transform: scale(1);
  }
  50% {
    opacity: 0.5;
    filter: alpha(opacity=50);
    -webkit-transform: scale(1.12);
  }
  100% {
    opacity: 0.2;
    filter: alpha(opacity=20);
    -webkit-transform: scale(1);
  }
}
</style>
