<!-- 视频监控 -->
<template>
	<div class="video">
					<div id="video-container1"></div>
	</div>
</template>

<script setup>
import { ref, onMounted, defineProps,onBeforeUnmount} from 'vue'
import EZUIKit from "ezuikit-js";
const props = defineProps(['othersId','accessToken','isMobile'])
var ezvizPlayOne = ref(null);
function autoVideoOne() {
    // 获取父节点的宽高
	if (ezvizPlayOne.value !== null) {
		return;
	}
	//获取萤石token
	ezvizPlayOne.value = new EZUIKit.EZUIKitPlayer({
		// autoplay: true,  // 默认播放
		//视频播放包裹元素
		id: "video-container1",
		//萤石token
		accessToken:props.accessToken,
		// ezopen://open.ys7.com/${设备序列号}/{通道号}.live
		url: props.othersId, // 播放地址
		template: "security", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
		plugin: ['talk'], // 加载插件，talk-对讲
		// 视频下方底部控件
		footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
		width: props.isMobile ? 300 : 800,
		height: props.isMobile ? 400 : 500,
	})

}
const	destroyEl = () => {
       ezvizPlayOne.value.play();
			 setTimeout(()=>{
				ezvizPlayOne.value.Talk.stopTalk()
			 },500)
    }
onMounted(() => {
	autoVideoOne();
})
onBeforeUnmount(()=>{
	destroyEl()
})

</script>
<style lang="scss" scoped>
.video {
	width: 100%;
	height: 80%;
}
</style>
